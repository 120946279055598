exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/App.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/Map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-smarthomes-js": () => import("./../../../src/pages/Smarthomes.js" /* webpackChunkName: "component---src-pages-smarthomes-js" */),
  "component---src-pages-techradar-js": () => import("./../../../src/pages/Techradar.js" /* webpackChunkName: "component---src-pages-techradar-js" */)
}

